@import "fonts";
// @import "bootstrap-variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
:root {
  --font-size: 16px;
  .KTDashboard-home {
    min-height: 100vh;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
@import "theme/index";
@import "commonColors";
