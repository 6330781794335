.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 6.4rem;
    height: 6.4rem;
}
.lds-ellipsis div {
    position: absolute;
    top: 2.7rem;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background: #03522D;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 0.4rem;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 0.4rem;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 2.6rem;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 4.5rem;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
    }
    @keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
    }
    @keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(1.9rem, 0);
    }
}
.lds-ring-spinner-outer {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    // background-color: #eee;
    opacity: .8;

    .lds-ring {
        display: inline-block;
        position: absolute;
        top: calc(50% - 2.2rem);
        left: calc(50% - 2.2rem);
        width: 4.4rem;
        height: 4.4rem;

      }
      .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 3.1rem;
        height: 3.1rem;
        margin: 0.6rem;
        border: 0.4rem solid #44574E;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #44574E transparent transparent transparent;
      }
      .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
      }
      .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
      }
      .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
      }
      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

}
